<template>
  <div>
    <counters />
    <b-card no-body>
      <b-card-body>
        <search-bar
          :filter-action="filterSearch"
          :search-action="keywordSearch"
        />
      </b-card-body>
      <b-table
        responsive="sm"
        :fields="fields"
        :items="dataList"
        striped
      >
        <template #cell(brand)="data">
          {{ data.item.brand }} {{ data.item.model }}
          <div class="text-primary font-small-2">
            {{ data.item.title }}
          </div>
          <div class="text-warning font-small-2">
            {{ data.item.fuel }} {{ data.item.gear }}
          </div>
          <div class="text-warning font-small-2">
            {{ data.item.licence_plate }}
          </div>
        </template>
        <template #cell(stock_status)="data">
          <b-badge :variant="data.item.status_variant">
            {{ data.item.stock_status }}
          </b-badge>
        </template>
        <template #cell(control)="data">
          <div class="text-right width-150">
            <b-button
              block
              variant="primary"
              size="sm"
              :to="$route.path + '/view/' + data.item.id"
            >
              <FeatherIcon icon="EyeIcon" />
              Görüntüle
            </b-button>
            <b-button
              block
              variant="warning"
              size="sm"
              :to="$route.path + '/edit/' + data.item.id"
            >
              <FeatherIcon icon="EditIcon" /> Güncelle
            </b-button>
          </div>
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
  </div>
</template>
<script>
import {
  BCard, BTable, BPagination, BCardFooter, BBadge, BCardBody, BButton,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import SearchBar from '@/views/Rental/Stocks/Index/SearchBar.vue'
import Counters from '@/views/Rental/Stocks/Index/Counters.vue'

const tableName = 'com_rental_stocks'
export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BPagination,
    BCardFooter,
    BBadge,
    BCardBody,
    BButton,
    SearchBar,
    Counters,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      fields: [
        {
          key: 'arackodu',
          label: 'ARAÇ KODU',
          thClass: 'width-150',
        },
        {
          key: 'brand',
          label: 'ARAÇ BİLGİSİ',
        },
        {
          key: 'stock_status',
          label: 'DURUM',
        },
        {
          key: 'control',
          label: '',
          thStyle: { width: '200px' },
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.arackodu AS arackodu`,
          `${tableName}.title AS title`,
          `${tableName}.licence_plate AS licence_plate`,
          'com_swap_brand.name AS brand',
          'com_swap_model.title AS model',
          'com_fuel.title AS fuel',
          'com_gear.title AS gear',
          'com_rent_stock_status.title AS stock_status',
          'com_rent_stock_status.variant AS status_variant',
          `${tableName}.id_com_rent_stock_status AS id_com_rent_stock_status`,
        ],
        i2Filter: false,
        limit: 20,
        start: 0,
      },
    }
  },
  computed: {
    searchData() {
      return this.$store.getters['rentalStocks/searchData']
    },
    detailFilter() {
      return this.$store.getters['rentalStocks/detailFilter']
    },
    dataList() {
      return this.$store.getters['rentalStocks/dataList']
    },
    dataCounts() {
      return this.$store.getters['rentalStocks/dataCounts']
    },
    saveData() {
      return this.$store.getters['rentalStocks/dataSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 20
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
    this.getWaitDataList()
  },
  methods: {
    keywordSearch() {
      this.dataQuery.or_like = {
        'com_rental_stocks.arackodu': this.searchData.keyword,
        'com_rental_stocks.licence_plate': this.searchData.keyword,
        'com_rental_stocks.chasis': this.searchData.keyword,
      }
      this.getDataList()
    },
    filterSearch() {
      const where = {}
      if (this.detailFilter.id_com_rent_stock_status) {
        where['com_rental_stocks.id_com_rent_stock_status'] = this.detailFilter.id_com_rent_stock_status
      }
      if (this.detailFilter.id_com_swap_brand) {
        where['com_rental_stocks.id_com_swap_brand'] = this.detailFilter.id_com_swap_brand
      }
      if (this.detailFilter.id_com_swap_model) {
        where['com_rental_stocks.id_com_swap_model'] = this.detailFilter.id_com_swap_model
      }
      if (this.detailFilter.id_com_fuel) {
        where['com_rental_stocks.id_com_fuel'] = this.detailFilter.id_com_fuel
      }
      if (this.detailFilter.id_com_gear) {
        where['com_rental_stocks.id_com_gear'] = this.detailFilter.id_com_gear
      }
      if (this.detailFilter.id_com_rent_stock_status !== '5') {
        if (this.detailFilter.sdate) {
          where['com_rental_stocks.registered >='] = this.detailFilter.sdate
        }
        if (this.detailFilter.edate) {
          where['com_rental_stocks.registered <='] = this.detailFilter.edate
        }
        this.dataQuery.i2Filter = false
      } else {
        if (this.detailFilter.sale_sdate) {
          where['DATE(com_rental_documents.sdate) >='] = this.detailFilter.sale_sdate
        }
        if (this.detailFilter.sale_edate) {
          where['DATE(com_rental_documents.sdate) <='] = this.detailFilter.sale_edate
        }
        where['com_rental_documents.id_com_rental_document_types'] = 6
        this.dataQuery.i2Filter = true
      }

      this.dataQuery.where = where
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('rentalStocks/getDataList', this.dataQuery)
    },
    getWaitDataList() {
      this.$store.dispatch('rentalStocks/getWaitDataList', { limit: 1, start: 0 })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
